.drillingWrapper .service p{
    border-left: 0px;
    padding: 0px;
}
.drillingWrapper{
    background-image: url("../../assets/images/drilling\ background.png");
    background-size: cover;
    background-position: top;
    height: fit-content;
    margin-top: 79px;
}
.drillingWrapper .service{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.service li{
    margin-left: 20px;
}
.drillingWrapper .service h2{
    font-size: 45px;
}
.drillingWrapper .service p{
    font-size: 17px;
    color: whitesmoke;
    width: 633px;
    line-height: 23px;
}
.drillingServWrapper{
    padding: 150px 10%;
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
    position: relative;
    align-items: center;
}
.leftDrillingWrapper{
    width: 50%;
}
.leftDrillingWrapper .rect{
    position: absolute;
    z-index: -1;
    left: 0px;
    height: 100%;
    width: 35%;
    clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%);
    top: 0px;
    background-color: var(--blue);
}
.leftDrillingWrapper .rect2{
    position: absolute;
    z-index: 1;
    right: 0px;
    height: 100%;
    width: 35%;
    clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
    top: 0px;
    background-color: var(--blue);
}
.drillingWrapperBackground{
    background-image: url("../../assets/images/oil\ worker.jpg");
    background-size: cover;
}
.drillingServWrapper2{
    background-color: rgba(255, 255, 255, 0.905);
}
.leftDrillingWrapper img{
    background-color: gray;
    width: 100%;
    height: 480px;
    object-fit: cover;
}
.leftDrillingWrapper2 img{
    object-fit: contain;
    position: relative;
    z-index: 2;
    background-color: rgba(128, 128, 128, 0);
}

.rightDrillingWrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.rightDrillingWrapper h2{
    font-size: 35px;
    color: var(--blue);
}
.rightDrillingWrapper2 ul{
    padding-bottom: 20px;
}
.rightDrillingWrapper p{
    font-size: var(--big-text);
    color: var(--gray);
    width: 100%;
    line-height: 23px;
}
.rightDrillingWrapper ul{
    padding-top: 10px;
}
.rightDrillingWrapper li{
    margin-left: 20px;
}

.drilling{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 150px 10%;
}
.leftdrilling{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 48%;
}
.leftdrilling h2{
    font-size: 35px;
    color: var(--blue);
}
.leftdrilling p{
    font-size: var(--big-text);
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    line-height: 23px;
    color: var(--gray);
}
.leftdrilling li{
    margin-left: 20px;
}
.leftdrilling img{
    top: -10px;
    left: -20px;
    position: absolute;
    height: 87px !important;
    width: 292px !important;
    background-color: rgba(255, 255, 255, 0) !important;
}
.drilling img{
    width: 48%;
    height: 400px;
    object-fit: cover;
    background-color: gray;
}
.drillingAsh{
    background-color: var(--ash);
}
.rightDrilling{
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    width: 48%;
}
.rightDrilling img{
    width: 100%;
}
.rightDrilling img:nth-child(1){
    height: 100px;
    background-color: transparent;
}

@media(max-width: 1300px){
    .drillingAimWrapper{
        padding: 150px 5%;
    }
    .drillingServWrapper{
        padding: 150px 5%;
    }
    .drilling{
        padding: 150px 5%;
    }
}
@media(max-width: 1000px){
    .drillingAimWrapper{
        flex-direction: column;
        row-gap: 100px;
    }
    .drillingServWrapper{
        flex-direction: column-reverse;
        row-gap: 100px;
    }
    .drilling{
        flex-direction: column-reverse;
        padding: 100px 5%;
        row-gap: 60px;
    }
    .drilling img{
        width: 100%;
        height: 300px;
    }
    .leftdrilling{
        width: 100%;
    }
    .drillingAsh{
        flex-direction: column;
    }
    .rightDrilling{
        width: 100%;
    }
    .drillingAimWrapper .rect{
        height: 50%;
        width: 50%;
        clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%);
    }
    .drillingServWrapper .rect{
        height: 50%;
        width: 50%;
        clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%);
    }
    .leftDrillingWrapper .rect2{
        width: 50%;
        height: 50%;
        clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
    .leftDrillingWrapper{
        width: 100%;
    }
    .leftDrillingWrapper img{
        height: 350px;
        margin-top: -50px;
        width: 100%;
    }
    .rightDrillingWrapper{
        width: 100%;
    }
    .drillingWrapper .service h2{
        font-size: 38px;
    }
    .drillingWrapper .service{
        row-gap: 10px;
    }
    .drillingWrapper .service p{
        font-size: var(--big-text);
    }
    .rightDrillingWrapper h2{
        font-size: 28px;
    }
    .rightDrillingWrapper p{
        font-size: var(--small-text);
    }
    .leftdrilling h2{
        font-size: 28px;
    }
    .leftdrilling p{
        font-size: var(--small-text);
    }
}

@media(max-width: 750px){
    .drillingWrapper .service p{
        width: 100%;
        font-size: var(--small-text);
    }
    .drillingWrapper .service h2{
        font-size: 28px;
    }
    .rightDrillingWrapper h2{
        font-size: 26px;
    }
    .rightDrillingWrapper p{
        font-size: var(--small-text);
    }
    .leftDrillingWrapper img{
        height: 300px;
        margin-top: -50px;
        width: 100%;
    }
    .leftdrilling h2{
        font-size: 25px;
    }
    .leftdrilling p{
        font-size: var(--small-text);
    }
}
@media(max-width: 650px){
    .drillingAimWrapper .rect{
        height: 45%;
    }
    .drillingServWrapper .rect{
        height: 45%;
    }
    .leftDrillingWrapper .rect2{
        height: 45%;
    }
    .leftDrillingWrapper img{
        margin-top: -70px;
    }
}
@media(max-width: 500px){
    .drillingAimWrapper .rect{
        height: 45%;
        width: 70%;
    }
    .drillingServWrapper .rect{
        height: 45%;
        width: 70%;
    }
    .drillingAimWrapper{
        padding-bottom: 50px;
    }
    .drillingServWrapper{
        padding-bottom: 50px;
    }
    .leftDrillingWrapper img{
        margin-top: -70px;
        height: 250px;
    }
    .leftDrillingWrapper .rect2{
        height: 45%;
        width: 70%;
        
    }
    .drilling img{
        height: 250px;
    }
    .rightDrilling img{
        height: 250px;
    }
    .leftDrillingWrapper h2{
        font-size: 23px;
    }
    .leftdrilling h2{
        font-size: 23px;
    }
}

@media(max-width: 420px){
    .drillingAimWrapper .rightDrillingWrapper{
        margin-top: 50px;
    }
}