
.aboutHeroWrapper{
    display: flex;
    padding: 100px 10%;
    justify-content: space-between;
    column-gap: 30px;
    align-items: center;
    margin-top: 79px;
}
.leftAbout{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 50%;
}
.leftAbout h1{
    font-size: 35px;
    color: var(--blue);
}
.leftAbout p{
    font-size: var(--small-text);
    line-height: 23px;
    color: var(--gray);
}
.aboutDetails{
    display: flex;
    flex-direction: column;
    row-gap: var(--big-text);
}
.rightAbout{
    width: 50%;
}
.rightAbout img{
    background-color: gray;
    border-radius: 96px;
    width: 100%;
    background-color: gray;
    height: 420px;
}
.missionVisionWrapper{
    display: flex;
    flex-direction: column;
    padding: 100px 10%;
    justify-content: center;
    align-items: center;
    
}
.missionWrapper{
    display: flex;
    height: 320px;
    width: 80%;

}
.mission{
    width: 50%;
    background-color: var(--blue);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: 5px;
    padding: 20px 30px;
}
.mission h3{
    font-size: 24px;
}
.mission p{
    font-size: var(--small-text);
    color: rgb(205, 205, 205);
    line-height: 20px;
}
.mission ul{
    text-align: left;
}
.missionWrapper img{
    object-fit: cover;
    height: 100%;
    width: 50%;
    object-position: center;
}
.vision{
    background-color: #ba3333;
}
.vissionWrapper{
    flex-direction: row-reverse;
}

.valuesWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 60px 10%;
    padding-bottom: 160px;
    align-items: center;
}
.valuesWrapper h2{
    font-size: 28px;
    color: var(--blue);
}
.valuesCardWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: 20px;
}
.valuesCard{
    width: 31.3%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    text-align: center;
}
.valuesCard svg{
    color: var(--red);
    font-size: 25px;
}
.valuesCard h3{
    font-size: 18px;
}
.valuesCard p{
    font-size: var(--big-text);
    color: var(--text);
    line-height: 23px;
}

@media(max-width: 1300px){
    .aboutHeroWrapper{
        padding: 100px 5%;
    }
    .missionVisionWrapper{
        padding: 100px 5%;
    }
    .missionWrapper{
        width: 85%;
    }
    .valuesWrapper {
        padding: 60px 5%;
    }
}
@media(max-width: 1000px){
    .rightAbout img{
        height: 500px;
    }
    .leftAbout h1{
        font-size: 28px;
    }
    .missionVisionWrapper{
        padding: 60px 5%;
    }
    .missionWrapper{
        width: 100%;
    }
    .valuesCard{
        width: 48.5%;
    }
}
@media(max-width: 850px){
    .aboutHeroWrapper{
        flex-direction: column;
        row-gap: 30px;
    }
    .valuesCard p{
        font-size: var(--small-text);
    }
    .leftAbout{
        align-items: center;
        text-align: center;
        width: 100%;
    }
    .rightAbout {
        width: 80%;
    }
    .rightAbout img{
        height: 350px;
    }
}
@media(max-width: 750px){
    .rightAbout{
        width: 100%;
    }
    .missionWrapper{
        height: fit-content;
        flex-direction: column;
    }
    .mission{
        width: 100%;
        height: 300px;
    }
    .missionWrapper img{
        width: 100%;
        height: 300px;
    }
}
@media(max-width: 500px){
    
    .valuesCard{
        width: 100%;
    }
    .valuesWrapper h2{
        font-size: 24px;
    }
}
@media(max-width: 450px){
    .leftAbout h1{
        font-size: 25px;
    }
    .rightAbout img{
        height: 320px;
        border-radius: 50px;
    }
    .mission{
        height: fit-content;
        padding: 30px 20px;
    }
    
    .vision {
        height: 250px;
    }
    .mission h3{
        font-size: 20px;
    }
    .mission p{
        font-size: var(--small-text);
    }
    .missionWrapper img{
        height: 220px;
    }
}