.waterManagementWrapper .service p{
    border-left: 0px;
    padding: 0px;
}
.waterManagementWrapper{
    background-image: url("../../assets/images/waterManagementBackground.png");
    background-size: cover;
    background-position: center;
    height: fit-content;
    margin-top: 79px;
}
.waterManagementWrapper .service{
    height: 88vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.waterManagementWrapper .service h2{
    font-size: 45px;
}
.waterManagementWrapper .service p{
    font-size: 17px;
    color: whitesmoke;
    width: 633px;
    line-height: 23px;
}
.topContent{
    padding: 100px 10%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.topContent p{
    font-size: var(--big-text);
    color: var(--gray);
    width: 100%;
    line-height: 21px;
}
.managementTable{
    width: fit-content;
    height: fit-content;
    background-image: url("../../assets/images/tableBackground.png");
    width: 100%;
    background-size: cover;
    background-position: center;
}
.toverlay{
    padding: 100px 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.833);
}
.managementTable img{
    width: 70%;
    height: auto;
    object-fit: cover;
}   
.bottomContent{
    padding: 100px 10%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.bottomContent p{
    font-size: var(--big-text);
    line-height: 21px;
    color: var(--gray);
}
.bottomContent li{
    margin-left: 20px;
}


@media(max-width: 1300px){
    .topContent{
        padding: 100px 5%;
    }
    .bottomContent{
        padding: 100px 5%;
    }
    .waterManagementWrapper .service h2{
        font-size: 40px;
    }
    .waterManagementWrapper .service p{
        font-size: var(--big-text);
    }
}
@media(max-width: 1000px){
    .waterManagementWrapper .service h2{
        font-size: 35px;
    }
    .topContent p{
        font-size: var(--small-text);
    }
    .managementTable img{
        width: 90%;
    }
    .bottomContent p{
        font-size: var(--small-text);
    }

}
@media(max-width: 750px){
    .waterManagementWrapper .service h2{
        font-size: 30px;
    }
    .waterManagementWrapper .service p{
        font-size: var(--small-text);
        width: 100%;
    }
    .topContent p{
        font-size: var(--small-text);
    }
    .toverlay{
        padding: 100px 5%;
    }
    .managementTable img{
        width: 100%;
    }
    .bottomContent p{
        font-size: var(--small-text);
    }

}
@media(max-width: 500px){
    .waterManagementWrapper .service h2{
        font-size: 26px;
    }
}