footer {
  background-color: black;
  color: white;
  border-top: 1px solid rgb(46, 46, 46);
  display: flex;
  justify-content: space-between;
  padding: 80px 10%;
}

.companyInfo {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 25%;
}
.companyInfo img {
  width: 200px;
  height: 40px;
}
.companyInfo p {
  font-size: var(--big-text);
  line-height: 22px;
  color: rgb(190, 190, 190);
}

.companyInfo form {
  display: flex;
}
.companyInfo form input {
  font-size: var(--big-text);
  width: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: none;
}
.companyInfo form button {
  background-color: white;
  color: green;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0px 20px;
  border: 1px solid var(--blue);
  font-size: 12px;
  font-weight: 700;
}
.companyInfo form button:hover {
  background-color: rgb(227, 227, 227);
  transition: 500ms;
}
.companyInfo form input:focus {
  border: 0px;
}

footer ul {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}
footer ul h3 {
  font-size: 18px;
  font-weight: 600;
}
footer ul a {
  font-size: var(--big-text);
  color: rgb(210, 210, 210);
  font-weight: 400;
  text-decoration: none;
}
footer .footer_menu {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 5px;
}
.footer_links {
  display: flex;
  column-gap: 20px;
  flex-direction: row;
}
.footer_links svg {
  margin-top: 5px;
  font-size: 20px;
  color: rgb(210, 210, 210);
}
.copyright {
  background-color: black;
  padding: 25px 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-top: 1px solid rgb(157, 157, 157);
  font-size: var(--small-text);
}
.copyright span {
  color: rgb(190, 190, 190);
}
.contact {
  width: 23%;
}
.contact p {
  font-size: var(--big-text);
  display: flex;
  column-gap: 10px;
  align-items: center;
  color: rgb(190, 190, 190);
}
.contact h3 {
  font-size: 18px;
}

.contact p svg {
  color: white;
  font-size: 20px !important;
}
.contact .socials {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.contact .footer_menu h3 {
  font-size: 18px;
}
.downloadWrapper {
  padding: 70px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 9px;
  background-color: black;
  color: white;
}
.downloadWrapper h3 {
  font-size: 27px;
}
.downloadWrapper a {
  text-decoration: none;
  color: white;
  background-color: var(--red);
  padding: 10px 25px;
  border-radius: 25px;
  font-size: var(--small-text);
}
.downloadWrapper a:hover {
  background-color: var(--hover);
  transition: 500ms;
  border-radius: 10px;
}

@media (max-width: 1300px) {
  footer {
    padding: 40px 5%;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 40px;
  }
  .companyInfo {
    width: 40%;
  }
  .contact {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .companyInfo {
    width: 100%;
  }
  footer ul {
    width: 200px;
  }
  .contact {
    width: 100%;
  }
}
@media (max-width: 450px) {
  footer {
    flex-direction: column;
  }
  footer ul {
    width: 100%;
  }
  .contact .footer_menu h3 {
    font-size: 16px;
  }
}
