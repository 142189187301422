@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
:root{
  --red: #DF1818;
  --blue: #01224F;
  --hover: #e32b2b;
  --gray: #404040;
  --ash: rgba(0, 0, 0, 0.12);
  --brown: #6A5C56;
  --big-text: 16px;
  --small-text: 15px;
}
body {
  margin: 0;
  transition: 200ms;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.show{
  display: contents;
}
.hide{
  display: none;
}