.wellWrapper{
    margin-top: 79px;
    background-image: url("../../assets/images/Well.png");
    width: 100%;
    background-size: cover;
    background-position: center;
}
.wellOverlay{
    background-color: rgba(0, 0, 0, 0.566);
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    padding: 100px 10%;
    justify-content: center;
}
.wellOverlay h2{
    font-size: 45px;
    color: white;
}
.wellOverlay p{
    font-size: 17px;
    width: 633px;
    line-height: 23px;
    color: whitesmoke;
}
.wellServices{
    display: flex;
    padding: 150px 10%;
    justify-content: space-between;
    align-items: center;
}
.wellServices img{
    width: 48%;
    height: 350px;
    object-fit: cover;
    object-position: center;
    background-color: gray;
}
.wellServices .content{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 48%;
}
.wellServices .content h2{
    font-size: 35px;
    color: var(--blue);
}
.wellServices li{
    margin-left: 20px;
}
.wellServices p{
    line-height: 23px;
}
.wellServices ul{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.acquisitionWrapper{
    background-image: url('../../assets/images/Big\ water\ pipes.png');
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: fit-content;
}
.acquisitionOverlay{
    padding: 150px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(236, 236, 236, 0.916);
}
.acquisitionOverlay .topSection{
    display: flex;
    justify-content: space-between;
}
.topSection .content{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 70%;
}
.topSection .content h2{
    font-size: 35px;
    color: var(--blue);
}
.topSection p{
    font-size: var(--big-text);
    color: var(--gray);
    line-height: 23px;
}
.topSection li{
    margin-left: 20px;
}
.topSection img{
    width: 30%;
    height: auto;
    object-fit: cover;
}
.acquisitionOverlay img{
    width: 306px;
    height: 244px;
    margin-top: -50px;
    object-fit: cover;
}
.productivityWrapper{
    display: flex;
    padding: 150px 10%;
    justify-content: space-between;
    align-items: center;
}
.productivityWrapper img{
    width: 48%;
    height: 450px;
    object-fit: cover;
    object-position: center;
    background-color: gray;
}
.productivityWrapper .content{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 48%;
}
.productivityWrapper .content h2{
    font-size: 35px;
    color: var(--blue);
}
.productivityWrapper li{
    margin-left: 20px;
}
.productivityWrapper .content p{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: var(--big-text);
    line-height: 23px;
}
.productivityWrapper .content h3{
    font-size: 17px;
}
.productivityWrapper ul{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.sandWrapper{
    display: flex;
    padding: 150px 10%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--ash);
}
.sandWrapper img{
    width: 48%;
    height: 350px;
    object-fit: cover;
    object-position: center;
    background-color: gray;
}
.sandWrapper .content{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 48%;
}
.sandWrapper .content h2{
    font-size: 35px;
    color: var(--blue);
}
.sandWrapper li{
    margin-left: 20px;
}
.sandWrapper .content p{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: var(--big-text);
    line-height: 23px;
}

.integrityWrapper{
    background-image: url('../../assets/images/water\ station.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: fit-content;
}
.integrityOverlay{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.907);
    padding: 150px 10%;
    
}
.integrityOverlay h2{
    font-size: 35px;
    color: var(--blue);
}
.integrityOverlay p{
    font-size: var(--big-text);
    line-height: 23px;
    color: var(--gray);
}


@media(max-width: 1300px){
    .wellOverlay{
        padding: 100px 5%;
    }
    .acquisitionOverlay{
        padding: 150px 5%;
    }
    .wellServices{
        padding: 150px 5%;
    }
    .productivityWrapper{
        padding: 150px 5%;
    }
    .sandWrapper{
        padding: 150px 5%;
    }
    .integrityOverlay{
        padding: 150px 5%;
    }
    .wellOverlay h2{
        font-size: 35px;
    }
    .wellOverlay p{
        font-size: var(--big-text);
    }
    .wellServices .content h2{
        font-size: 30px;
    }
    .wellServices p{
        font-size: var(--small-text);
    }
    .wellServices ul{
        font-size: var(--small-text);
    }
    .topSection .content h2{
        font-size: 30px;
    }
    .topSection p{
        font-size: var(--small-text);
    }
    .productivityWrapper .content h2{
        font-size: 30px;
    }
    .productivityWrapper .content p{
        font-size: var(--small-text);
    }
    .sandWrapper .content h2{
        font-size: 30px;
    }
    .sandWrapper .content p{
        font-size: var(--small-text);
    }
    .integrityOverlay h2{
        font-size: 30px;
    }
    .integrityOverlay p{
        font-size: var(--small-text);
    }
}
@media(max-width: 1000px){
    .wellServices{
        flex-direction: column;
        row-gap: 50px;
    }
    .wellServices img{
        width: 100%;
        height: 300px;
    }
    .wellServices .content{
        width: 100%;
    }
    .acquisitionOverlay img{
        margin-top: 0px;
    }
    .productivityWrapper{
        flex-direction: column;
        row-gap: 50px;
    }
    .productivityWrapper .content{
        width: 100%;
    }
    .productivityWrapper img{
        width: 100%;
        height: 300px;
    }
    .sandWrapper{
        flex-direction: column;
        row-gap: 50px;
    }
    .sandWrapper .content{
        width: 100%;
    }
    .sandWrapper img{
        width: 100%;
        height: 300px;
    }
    .wellOverlay h2{
        font-size: 35px;
    }
    .wellOverlay p{
        font-size: var(--big-text);
    }
    .wellServices .content h2{
        font-size: 28px;
    }
    .topSection .content h2{
        font-size: 28px;
    }
    .productivityWrapper .content h2{
        font-size: 28px;
    }
    .sandWrapper .content h2{
        font-size: 28px;
    }
    .integrityOverlay h2{
        font-size: 28px;
    }
}
@media(max-width: 750px){
    .wellServices{
        padding: 100px 5%;
    }
    .acquisitionOverlay{
        flex-direction: column;
        row-gap: 50px;
        padding: 100px 5%;
    }
    .topSection{
        flex-direction: column;
        align-items: center;
    }
    .topSection .content{
        width: 100%;
    }
    .wellOverlay p{
        width: 100%;
    }
}

@media(max-width: 500px){
    .wellOverlay h2{
        font-size: 28px;
    }
    .wellOverlay p{
        font-size: var(--small-text);
    }
    .wellServices .content h2{
        font-size: 23px;
    }
    .wellServices p{
        font-size: var(--small-text);
    }
    .wellServices ul{
        font-size: var(--small-text);
    }
    .topSection .content h2{
        font-size: 23px;
    }
    .topSection p{
        font-size: var(--small-text);
    }
    .productivityWrapper .content h2{
        font-size: 23px;
    }
    .productivityWrapper .content p{
        font-size: var(--small-text);
    }
    .sandWrapper .content h2{
        font-size: 23px;
    }
    .sandWrapper .content p{
        font-size: var(--small-text);
    }
    .integrityOverlay h2{
        font-size: 23px;
    }
    .integrityOverlay p{
        font-size: var(--small-text);
    }
    .wellServices img{
        height: 250px;
    }
    .acquisitionOverlay img{
        height: 250px;
    }
    .productivityWrapper .content h3{
        font-size: var(--big-text);
    }
    .productivityWrapper img{
        height: 250px;
    }
    .sandWrapper img{
        height: 250px;
    }
    .wellServices{
        padding: 100px 5%;
    }
    .acquisitionOverlay{
        padding: 100px 5%;
    }
    .productivityWrapper{
        padding: 100px 5%;
    }
    .sandWrapper{
        padding: 100px 5%;
    }
}

@media(max-width: 400px){
    .topSection img{
        width: 100%;
        height: auto;
    }
    .acquisitionOverlay img{
        width: 100%;
        height: auto;
    }
}