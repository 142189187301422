.hero{
    height: fit-content;
    background-image: url("../assets/images/homePageBackground.png");
    background-size: cover;
    background-position: center;
    margin-top: 79px;
}

.overlay{
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.407);
    color: white;
    padding: 0px 10%;
    row-gap: 15px;
}
.overlay h1{
    font-size: 35px;
}
.overlay p{
    border-left: 5px solid white;
    padding:  10px;
    width: 450px;
    font-size: 18px;
    color: whitesmoke;
}
.overlay button{
    background-color: var(--red);
    color: white;
    padding: 15px 30px;
    border-radius: 25px;
    border: 0px;
}
.overlay button:hover{
    cursor: pointer;
    transition: 500ms;
    background-color: var(--hover);
}

.history{
    padding: 100px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 30px;
}
.history h2{
    font-size: 30px;
    font-weight: 400;
    color: var(--red);
}
.history h3{
    width: 65%;
    font-weight: 500;
    font-size: 18px;
}
.history p{
    width: 90%;
    font-size: var(--big-text);
    line-height: 23px;
    color: rgb(91, 91, 91);
}
.history button{
    background-color: whitesmoke;
    border: 0px;
    display: flex;
    font-size: 14px;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}
.history button:hover{
    transition: 500ms;
    column-gap: 20px;
}
.history button svg{
    background-color: var(--red);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 6px;
}

.homeServices{
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    align-items: center;
}
.homeServices h1{
    font-size: 35px;
    color: var(--blue);
    font-weight: 700;
    text-align: center;
}
.serviceCard{
    display: flex;
    flex-direction: row;
    padding: 40px 13%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.otherServices{
    margin-top: 79px;
    padding: 0px 0px;
    height: fit-content;
    width: 100%;
    /* margin-bottom: 100px; */
    background-image: url("../assets/images/other services.jpeg.jpg");
    background-size: cover;
    background-position: center;
}
.otherServicesOverlay{
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.523);
}
.otherServices h1{
    color: white;
    font-size: 40px;
    text-align: center;
}
.ashService{
    padding: 130px 13%;
    margin: 50px 0;
    background-color: var(--ash);
}

.serviceImage{
    width: 45%;
    height: 400px;
    position: relative;
}
.serviceImage .rect{
    background-color: var(--red);
    left: -30px;
    top: -30px;
    width: 300px;
    height: 200px;
    z-index: -1;
    position: absolute;
}
.serviceImage img{
    background-color: gray;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.serviceContent{
    width: 46%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.serviceContent p{
    line-height: 23px;
    font-size: var(--big-text);
    color: rgb(91, 91, 91);
}
.serviceContent img{
    width: 60px;
    height: 60px;
}

.homeServices button{
    background-color: rgba(245, 245, 245, 0);
    border: 0px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 15px;
    cursor: pointer;
}
.homeServices button:hover{
    transition: 500ms;
    column-gap: 20px;
}
.homeServices button svg{
    background-color: var(--red);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 6px;
}
.balckbtn svg{
    background-color: var(--red);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 6px;
}
.banner{
    width: 100%;
    height: 250px;
    margin: 120px 0;
}
.banner img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.blogBannerWrapper{
    height: fit-content;
    width: 100%;
    background-image: url("../assets/images/blog\ background.png");
    background-size: cover;
    background-position: center;
    position: relative;
    margin: 100px 0px;
    align-items: center;
    column-gap: 50px;
    display: flex;

}
.balckbtn{
    background-color: black;
    color: white;
    padding: 3px 10px;
    border-radius: 25px;
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 20px;
    border: 0px;
    margin-top: 10px;
}
.blogBannerOverlay{
    height: fit-content;
    background-color: #404040be;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 50px;
    height: 80vh;
    width: 55%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    clip-path: polygon(0% 0%, 70% 0%, 100% 50%, 70% 100%, 0% 100%);
    
    align-items: flex-start;
}
.leftBlogBanner{
    color: white;
    width: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}
.leftBlogBanner h2{
    font-size: 35px;
}
.leftBlogBanner p{
    color: rgb(232, 232, 232);
    font-size: var(--big-text);
    line-height: 23px;
}
.blogrect{
    background-color: var(--red);
    top: -30px;
    left: 0px;
    height: 200px;
    position: absolute;
    z-index: -1;
    width: 350px;
}
.rightBlogBanner{
    display: flex;
    column-gap: 20px;
    justify-content: right;
    width: 45%;
    padding-right: 20px;
}
.blogBannerCard{
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 30px;
    height: fit-content;
}
.blogBannerCard img{
    border-radius: 30px;
    height: 200px;
    background-color: gray;
}
.blogBannerContent{
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: space-between;
}
.blogBannerContent .buttonWrap{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.blogBannerContent button{
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0);
    font-size: 14px;
    border: 0px;
    border-bottom: 2px solid var(--red);
    width: fit-content;
}
.blogBannerContent h3{
    font-size: 18px;
}
.blogBannerContent p{
    font-size: 14px;
}
.lastCard{
    margin-bottom: 100px;
}
.clientsWrapper{
    background-image: url("../assets/images/client.png");
    background-size: cover;
    background-position: center;
}
.clientsOverlay{
    padding: 150px 10%;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    background-color: rgba(255, 255, 255, 0.232);
}
.clientsWrapper h2{
    font-size: 35px;
    color: var(--blue);
    position: relative;
}
.clientsWrapper h2:after{
    content: '';
    background-color: var(--red);
    height: 8px;
    width: 100px;
    position: absolute;
    left: 65px;
    bottom: -10px;
}
.clientsCardWrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: 30px;
}
.clientsCardWrapper img{
    width: 22.7%;
    height: 130px;
    object-fit: contain;
}
.partnerCardWrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: 30px;
}
.partnerCardWrapper img{
    width: 22.7%;
    height: 130px;
    object-fit: contain;
}
.partnerWrapper{
    background-image: url("../assets/images/partner.png");
    background-size: cover;
    background-position: center;
}
.partnerOverlay{
    padding: 150px 10%;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    background-color: rgba(255, 255, 255, 0.232);
}
.partnerWrapper h2{
    font-size: 35px;
    color: var(--blue);
    position: relative;
}
.partnerWrapper h2:after{
    content: '';
    background-color: var(--red);
    height: 8px;
    width: 100px;
    position: absolute;
    left: 65px;
    bottom: -10px;
}
.serviceCard li{
    margin-left: 20px;
}
.partnerWrapper .lastLogo{
    background-color: rgb(107, 107, 107);
    height: 100px;
    margin-top: 14px;
}
@media(max-width: 1300px){
    .overlay{
        padding: 0px 5%;
    }
    .history{
        padding: 100px 5%;
    }
    .serviceCard{
        padding: 40px 5%;
    }
    .ashService{
        padding: 150px 5%;
    }
    .homeServices h1{
        font-size: 30px;
    }
    .blogBannerWrapper{
        background-position: left;
    }
    .blogBannerOverlay{
        padding: 100px 5%;
    }
    .rightBlogBanner{
        flex-direction: column;
        row-gap: 20px;
    }
    .blogBannerCard{
        width: 100%;
        flex-direction: row;
        column-gap: 0px;
        height: fit-content;
        align-items: center;
    }
    .blogBannerCard img{
        height: 180px;
        width: 40%;
    }
    .blogBannerContent{
        width: 60%;
        row-gap: 5px;
    }
    .clientsOverlay h2{
        font-size: 30px;
    }
    .partnerWrapper h2:after{
        left: 70px;
        height: 7px;
    }
    .clientsWrapper h2:after{
        height: 7px;
        left: 40px;
    }
    .partnerOverlay{
        padding: 100px 5%;
    }
    .clientsOverlay{
        padding: 100px 5%;
    }
}
@media(max-width: 1100px){
    .blogBannerWrapper{
        flex-direction: column;
        row-gap: 30px;
    }
    .blogBannerOverlay{
        width: 100%;
        padding: 100px 5%;
    }
    .rightBlogBanner{
        width: 100%;
        padding: 50px 5%;
        padding-bottom: 100px;
        flex-direction: row;
    }
    .blogBannerCard{
        width: 50%;
        flex-direction: column;
        border-radius: 20px;
    }
    .blogBannerCard img{
        height: 250px;
        width: 100%;
        border-radius: 20px;
    }
    .blogBannerContent{
        row-gap: 10px;
        width: 100%;
    }
}
@media(max-width: 1000px){
    .history h3{
        width: 80%;
    }
    .history p{
        width: 100%;
    }
    .serviceImage{
        height: 300px;
    }
    .serviceImage .rect{
        top: -20px;
        left: -20px;
    }
    .serviceContent img{
        height: 40px;
        width: 40px;
    }
    .homeServices h1{
        font-size: 27px;
    }
    .serviceContent{
        width: 50%;
        margin-top: -80px;
    }
    .serviceContent p{
        font-size: var(--small-text);
    }
    .blogBannerOverlay{
        row-gap: 20px;
    }
    .clientsCardWrapper img{
        width: 31.3%;
    }
    .partnerCardWrapper img{
        width: 31.3%;
    }
    
}
@media(max-width: 850px){
    .serviceCard{
        flex-direction: column;
        row-gap: 50px;
        height: fit-content;
    }
    .ashService{
        padding: 100px 5%;
    }
    .homeServices{
        row-gap: 40px;
        
    }
    .serviceContent{
        margin-top: 0px;
    }
    .banner{
        margin-bottom: 100px;
    }
    .serviceContent ul{
        text-align: left;
    }
    .serviceCard2{
        row-gap: 50px;
        flex-direction: column-reverse;
    }
    .serviceContent{
        width: 100%;
        align-items: center;
        text-align: center;
    }
    .homeServices h1{
        margin-bottom: -30px;
    }
    .serviceImage{
        width: 80%;
    }
    .clientsCardWrapper{
        row-gap: 10px;
    }
    .partnerCardWrapper{
        row-gap: 10px;
    }
}
@media(max-width: 750px){
    .history h2{
        font-size: 25px;
    }
    .history{
        padding: 80px 5%;
    }
    .overlay h1{
        font-size: 30px;
    }
    .overlay button{
        padding: 10px 20px;
    }
    .overlay p{
        font-size: var(--big-text);
    }
    .leftBlogBanner h2{
        font-size: 28px;
    } 
    .leftBlogBanner p{
        font-size: var(--small-text);
    }
    .rightBlogBanner{
        flex-direction: column;
        row-gap: 20px;
    }
    .blogBannerCard{
        flex-direction: row;
        width: 100%;
    }
    .blogBannerContent{
        justify-content: center;
        width: 55%;
    }
    .blogBannerCard img{
        width: 40%;
    }
    .leftBlogBanner{
        width: 100%;
    }
    .history h3{
        width: 100%;
    }
    .clientsOverlay h2{
        font-size: 25px;
    }
    .partnerOverlay h2{
        font-size: 25px;
    }
    .partnerWrapper h2:after{
        left: 40px;
        height: 5px;
    }
    .clientsWrapper h2:after{
        left: 30px;
        height: 5px;
    }
    .clientsOverlay{
        background-color: rgba(255, 255, 255, 0.463);
    }
    .partnerOverlay{
        background-color: rgba(255, 255, 255, 0.463);
    }
}
@media(max-width: 500px){
    .overlay p{
        width: 100%;
    }
    .otherServicesOverlay h1{
        font-size: 25px;
    }
    .overlay{
        height: 80vh;
    }
    .homeServices h1{
        font-size: 23px;
        margin-bottom: -30px;
    }
    .serviceImage{
        width: 93%;
    }
    .leftBlogBanner h2{
        font-size: 25px;
    }
    .serviceImage .rect{
        width: 200px;
        height: 150px;
        top: -15px;
        left: -15px;
    }
    .blogBannerCard{
        flex-direction: column;
    }
    .blogBannerWrapper{
        background-position: right;
    }
    .blogrect{
        width: 150px;
    }
    .blogBannerCard img{
        width: 100%;
        height: 170px;
    }
    .blogBannerContent{
        width: 100%;
    }
    .clientsCardWrapper img{
        width: 48.5%;
        margin-bottom: -50px;
    }
    .clientsCardWrapper{
        padding-bottom: 100px;
    }
    .partnerCardWrapper{
        padding-bottom: 100px;
    }
    .partnerCardWrapper2 img{
        margin-bottom: -50px;
        row-gap: 48.5%;
    }
    .partnerCardWrapper img{
        width: 48.5%;
        margin-bottom: -50px;
    }
}
@media(max-width: 450px){
    .overlay h1{
        font-size: 25px;
    }
    .overlay button{
        font-size:var(--small-text);
    }
    .overlay{
        height: 80vh;
    }
    .history h2{
        font-size: 20px;
    }
    .serviceImage{
        height: 230px;
    }
    .serviceContent h3{
        font-size: 18px;
    }
    .serviceContent img{
        height: 35px;
        width: 35px;
    }
    .leftBlogBanner p{
        font-size: var(--small-text);
        line-height: 20px;
    }
    .blogBannerContent h3{
        font-size: 15px;
    }
    .blogBannerOverlay {
        padding: 80px 5%;
        height: fit-content;
        clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
    }
}