.serviceWrapper .service p{
    border-left: 0px;
    padding: 0px;
}
.serviceWrapper{
    background-image: url("../../assets/images/engineering2.jpg");
    background-size: cover;
    background-position: top;
    height: fit-content;
    margin-top: 79px;
}
.serviceWrapper .service{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.serviceWrapper .service h2{
    font-size: 45px;
}
.serviceWrapper .service p{
    font-size: 17px;
    color: whitesmoke;
    width: 633px;
    line-height: 23px;
}
.aimWrapper{
    padding: 150px 10%;
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
    position: relative;
    align-items: center;
}
.leftAimWrapper{
    width: 50%;
}
.leftAimWrapper .rect{
    position: absolute;
    z-index: -1;
    left: 0px;
    height: 100%;
    width: 35%;
    clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%);
    top: 0px;
    background-color: var(--brown);
}
.leftAimWrapper .rect2{
    position: absolute;
    z-index: -1;
    right: 0px;
    height: 100%;
    width: 35%;
    clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
    top: 0px;
    background-color: var(--brown);
}
.leftAimWrapper img{
    background-color: gray;
    width: 100%;
    height: 480px;
    object-fit: cover;
}

.rightAimWrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.rightAimWrapper h2{
    font-size: 35px;
    color: var(--blue);
}
.rightAimWrapper2 ul{
    padding-bottom: 20px;
}
.rightAimWrapper p{
    font-size: var(--big-text);
    color: var(--gray);
    width: 100%;
    line-height: 23px;
}
.rightAimWrapper ul{
    padding-top: 10px;
}
.rightAimWrapper li{
    margin-left: 20px;
}

.banner2 img{
    width: 100%;
    background-color: gray;
    height: 270px;
    margin: 100px 0px;
    object-fit: cover;
    object-position: center;
}

.procurement{
    padding: 150px 10%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
}
.procurement h2{
    font-size: 35px;
    color: var(--blue);
}
.procurement .content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.procurement .content h3{
    font-size: 17px;
}
.procurement .content li{
    font-size: var(--big-text);
    color: var(--gray);
    margin-left: 20px;
}
.procurement .content ul{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.procurement img{
  width: auto;
  height: auto;
  object-fit: cover;  
}

.imagery{
    background-color: var(--ash);
    padding: 150px 10%;
    display: flex;
    column-gap: 50px;
    align-items: center;
    justify-content: space-between;
}
.leftImagery{
    width: 48%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.leftImagery h2{
    font-size: 35px;
    color: var(--blue);

}
.leftImagery p{
    color: var(--gray);
    font-size: var(--big-text);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    line-height: 23px;
}
.imagery img{
    width: 48%;
    height: 400px;
    background-color: gray;
    object-fit: cover;
}
.laser{
    padding: 150px 10%;
    display: flex;
    column-gap: 50px;
    align-items: center;
    justify-content: space-between;
}
.leftLaser{
    width: 48%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.leftLaser h2{
    font-size: 35px;
    color: var(--blue);

}
.leftLaser ul{
    color: var(--gray);
    font-size: var(--big-text);
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    line-height: 23px;
}
.leftLaser li{
    margin-left: 20px;
}
.laser img{
    width: 48%;
    height: 400px;
    background-color: gray;
    object-fit: cover;
}
.documentation{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    background-color: var(--ash);
    padding: 150px 10%;
}
.documentation h2{
    font-size: 35px;
    color: var(--blue);
}
.documentation img{
    height: auto;
    width: auto;
    object-fit: cover;
}


@media(max-width: 1300px){
    .aimWrapper{
        padding: 150px 5%;
    }
    .procurement {
        padding: 150px 5%;
        padding-top: 50px;
    }
    .imagery{
        padding: 150px 5%;
    }
    .laser{
        padding: 150px 5%;
    }
    .documentation{
        padding: 150px 5%;
    }
    .documentation img{
        width: 100%;
        height: auto;
    }
}
@media(max-width: 1000px){
    .aimWrapper{
        flex-direction: column;
        row-gap: 100px;
    }
    .aimWrap2{
        flex-direction: column-reverse;
    }
    .aimWrapper .rect{
        height: 50%;
        width: 50%;
        clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%);
    }
    .leftAimWrapper .rect2{
        width: 50%;
        height: 45%;
        clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
    .leftAimWrapper{
        width: 100%;
    }
    .leftAimWrapper img{
        height: 350px;
        margin-top: -50px;
        width: 100%;
    }
    .rightAimWrapper{
        width: 100%;
    }
    .procurement .content{
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .procurement .content ul{
        width: 50%;
    }
    .imagery{
        flex-direction: column;
        row-gap: 30px;
    }
    .leftImagery{
        width: 100%;
    }
    .imagery img{
        width: 100%;
        height: 350px;
        object-position: center;
    }
    .laser{
        flex-direction: column-reverse;
        row-gap: 30px;
    }
    .leftLaser{
        width: 100%;
    }
    .laser img{
        width: 100%;
        height: 350px;
        object-position: center;
    }
    .serviceWrapper .service h2{
        font-size: 38px;
    }
    .serviceWrapper .service{
        row-gap: 10px;
    }
    .serviceWrapper .service p{
        font-size: var(--big-text);
    }
    .rightAimWrapper h2{
        font-size: 30px;
    }
    .rightAimWrapper p{
        font-size: var(--small-text);
    }
    .procurement h2{
        font-size: 30px;
    }
    .procurement .content ul{
        font-size: var(--small-text);
    }
    .leftImagery h2{
        font-size: 30px;
    } 
    .leftImagery p{
        font-size: var(--small-text);
    }
    .leftLaser h2{
        font-size: 30px;
    } 
    .leftLaser ul{
        font-size: var(--small-text);
    }
    .documentation h2{
        font-size: 30px;
    }
}

@media(max-width: 750px){
    .serviceWrapper .service p{
        width: 100%;
        font-size: var(--small-text);
    }
    .serviceWrapper .service h2{
        font-size: 28px;
    }
    .rightAimWrapper h2{
        font-size: 26px;
    }
    .rightAimWrapper p{
        font-size: var(--small-text);
    }
    .procurement h2{
        font-size: 26px;
        text-align: center;
    }
    .procurement .content ul{
        font-size: var(--small-text);
    }
    .leftImagery h2{
        font-size: 26px;
    } 
    .leftImagery p{
        font-size: var(--small-text);
    }
    .leftLaser h2{
        font-size: 26px;
    } 
    .leftLaser ul{
        font-size: var(--small-text);
    }
    .documentation h2{
        font-size: 26px;
    }
    .leftAimWrapper img{
        height: 300px;
        margin-top: -50px;
        width: 100%;
    }
}
@media(max-width: 650px){
    .aimWrapper .rect{
        height: 45%;
    }
    .leftAimWrapper .rect2{
        height: 40%;
    }
    .leftAimWrapper img{
        margin-top: -70px;
    }
    .procurement img{
        width: 100%;
        height: auto;
    }
}
@media(max-width: 500px){
    .aimWrapper .rect{
        height: 45%;
        width: 70%;
    }
    .aimWrapper{
        padding-bottom: 50px;
    }
    .leftAimWrapper img{
        margin-top: -70px;
        height: 280px;
    }
    .leftAimWrapper .rect2{
        height: 40%;
        width: 70%;
        
    }
    .procurement .content{
        flex-direction: column;
    }
    .procurement .content ul{
        width: 100%;
    }
    .imagery{
        padding: 100px 5%;
    }
    .imagery img{
        height: 250px;
    }
    .laser{
        padding: 100px 5%;
    }
    .laser img{
        height: 250px;
    }
    .documentation h2{
        font-size: 25px;
        text-align: center;
    }
    .documentation{
        padding: 100px 5%;
    }
    .leftImagery h2{
        font-size: 25px;
    }
    .leftLaser h2{
        font-size: 25px;
    }
    .procurement h2{
        font-size: 25px;
    }
    .leftAimWrapper h2{
        font-size: 25px;
    }
}