.seismicWrapper{
    margin-top: 79px;
    background-image: url("../../assets/images/seismic\ bckground.png");
    width: 100%;
    background-size: cover;
    background-position: top;
}
.seismicOverlay{
    background-color: rgba(0, 0, 0, 0.566);
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    padding: 100px 10%;
    justify-content: center;
}
.seismicOverlay h2{
    font-size: 45px;
    color: white;
}
.seismicOverlay p{
    font-size: 17px;
    width: 633px;
    line-height: 23px;
    color: whitesmoke;
}
.processingWrapper{
    display: flex;
    padding: 150px 10%;
    justify-content: space-between;
    align-items: center;
}
.processingWrapper img{
    width: 48%;
    height: 400px;
    object-fit: cover;
    object-position: center;
    background-color: gray;
}
.processingWrapper .content{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 48%;
}
.processingWrapper .content h2{
    font-size: 32px;
    color: var(--blue);
}
.processingWrapper li{
    margin-left: 20px;
}
.processingWrapper p{
    line-height: 23px;
}
.processingWrapper ul{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.enhancementWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--ash);
    padding: 150px 10%;
    row-gap: 40px;
}
.topWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topWrapper img{
    width: 48%;
    height: 400px;
    object-fit: cover;
    object-position: center;
    background-color: gray;
}
.topWrapper .content{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 48%;
}
.topWrapper .content h2{
    font-size: 32px;
    color: var(--blue);
}
.topWrapper li{
    margin-left: 20px;
    line-height: 23px;
}
.topWrapper p{
    line-height: 23px;
}
.topWrapper ul{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.bottomWrapper{
    width: 60%;
}
.bottomWrapper h2{
    font-size: 35px;
    color: var(--blue);
}
.bottomWrapper ul{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.bottomWrapper  li{
    margin-left: 20px;
    line-height: 23px;
}
.analysisWrapper{
    padding: 150px 10%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.contentWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.analysisWrapper h2{
    font-size: 32px;
    color: var(--blue);
}
.contentWrapper .content{
    /* width: 65%; */
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.conentImages{
    /* width: 30%; */
    display: flex;
    flex-direction: column;
}
.contentWrapper .content ul{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.contentWrapper .content li{
    line-height: 23px;
    margin-left: 20px;
}
.contentWrapper .content h2{
    margin-top: 20px;
}
.softwareWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 150px 10%;
    background-color: var(--ash);
}

.softwareWrapper h2{
    font-size: 32px;
    color: var(--blue);
    row-gap: 40px;
}
.topSoftwareSection{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.topSoftwareSection p{
    line-height: 23px;
    color: var(--gray);
    font-size: var(--big-text);
}
.bottomSoftwareSection{
    display: flex;
    justify-content: space-between;
    row-gap: 10px;
    column-gap: 30px;
    align-items: center;
}
.bottomSoftwareSection ul{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.bottomSoftwareSection li{
    margin-left: 20px;
}
.bottomSoftwareSection .content{
    width: 48%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.bottomSoftwareSection img{
    width: 48%;
    height: 300px;
    object-fit: cover;
}

@media(max-width: 1300px){
    .seismicOverlay{
        padding: 100px 5%;
    }
    .processingWrapper{
        padding: 150px 5%;
    }
    .enhancementWrapper{
        padding: 150px 5%;
    }
    .analysisWrapper{
        padding: 150px 5%;
    }
    .softwareWrapper{
        padding: 150px 5%;
    }
}
@media(max-width: 1000px){
    .seismicOverlay h2{
        font-size: 40px;
    }
    .seismicOverlay p{
        font-size: var(--big-text);
    }
    .processingWrapper{
        flex-direction: column;
        row-gap: 50px;
    }
    .processingWrapper img{
        width: 100%;
        height: 300px;
    }
    .processingWrapper .content{
        width: 100%;
    }
    .topWrapper{
        flex-direction: column;
        row-gap: 70px;
    }
    .enhancementWrapper img{
        width: 100%;
        height: 300px;
    }
    .topWrapper .content{
        width: 100%;
    }
    .bottomWrapper{
        width: 100%;
        padding-top: 40px;
    }
    .analysisWrapper{
        flex-direction: column;
    }
    .contentWrapper{
        flex-direction: column;
        row-gap: 50px;
    }
    .contentImages{
        column-gap: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .contentImages img{
        width: 50%;
        height: auto;
    }
    .processingWrapper .content h2{
        font-size: 28px;
    }
    .bottomWrapper h2{
        font-size: 28px;
    }
    .processingWrapper p{
        font-size: var(--big-text);
    }
    .topWrapper .content h2{
        font-size: 28px;
    }
    .topWrapper ul{
        font-size: var(--big-text);
    }
    .bottomWrapper ul{
        font-size: var(--big-text);
    }
    .analysisWrapper h2{
        font-size: 28px;
    }
    .contentWrapper .content ul{
        font-size: var(--big-text);
    }

}
@media(max-width: 750px){
    .seismicOverlay h2{
        font-size: 35px;
    }
    .seismicOverlay p{
        font-size: var(--small-text);
        width: 100%;
    }
    .processingWrapper .content h2{
        font-size: 25px;
    }
    .processingWrapper p{
        font-size: var(--small-text);
    }
    .topWrapper .content h2{
        font-size: 25px;
    }
    .topWrapper ul{
        font-size: var(--small-text);
    }
    .bottomWrapper h2{
        font-size: 25px;
    }
    .bottomWrapper ul{
        font-size: var(--small-text);
    }
    .analysisWrapper h2{
        font-size: 25px;
    }
    .analysisWrapper{
        padding: 100px 5%;
    }
    .enhancementWrapper{
        padding: 100px 5%;
    }
    .processingWrapper{
        padding: 100px 5%;
    }
    .contentWrapper .content ul{
        font-size: var(--small-text);
    }
    .softwareWrapper{
        padding: 100px 5%;
    }
    .softwareWrapper h2{
        font-size: 26px;
    }
    .topSoftwareSection p{
        font-size: var(--small-text);
    }
    .bottomSoftwareSection ul{
        font-size: var(--small-text);
    }
    .bottomSoftwareSection{
        flex-direction: column-reverse;
        row-gap: 30px;
    }
    .bottomSoftwareSection .content{
        width: 100%;
    }
    .bottomSoftwareSection img{
        width: 100%;
        height: 280px;
    }
}
@media(max-width: 500px){
    .seismicOverlay  h2{
        font-size: 27px;
    }
    .seismicOverlay p{
        font-size: 13.5px;
    }
    .processingWrapper img{
        height: 250px;
    }
    .processingWrapper .content h2{
        font-size: 23px;
    }
    .topWrapper img{
        height: 250px;
    }
    .topWrapper .content h2{
        font-size: 23px;
    }
    .bottomWrapper h2{
        font-size: 23px;
    }
    .analysisWrapper h2{
        font-size: 23px;
    }
    .contentWrapper .content h2{
        font-size: 23px;
    }
    .contentImages{
        flex-direction: column;
        row-gap: 20px;
    }
    .contentImages img{
        width: 100%;
    }
    .softwareWrapper h2{
        font-size: 23px;
    }
    .bottomSoftwareSection img{
        height: 250px;
    }
}