.subSurfaceHero{
    margin-top: 79px;
    background-image: url("../../assets/images/surfaceBackground.png");
    background-size: cover;
    background-position: top;
    height: fit-content;
}
.suboverlay{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.51);
    color: white;
    padding: 0px 10%;
    row-gap: var(--big-text);
}
.subSurfaceHero .suboverlay h2{
    font-size: 45px;
}
.subSurfaceHero .suboverlay p{
    font-size: 17px;
    color: whitesmoke;
    width: 680px;
    line-height: 23px;
}
.suboverlay ul{
    padding-top: 10px;
}
.suboverlay li{
    margin-left: 20px;
}

.seismic{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 150px 10%;
}
.leftSeismic{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 48%;
}
.leftSeismic h2{
    font-size: 35px;
    color: var(--blue);
}
.leftSeismic p{
    font-size: var(--big-text);
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    line-height: 23px;
    color: var(--gray);
}
.leftSeismic li{
    margin-left: 20px;
}
.leftSeismic img{
    top: -10px;
    left: -20px;
    position: absolute;
    height: 87px !important;
    width: 292px !important;
    background-color: rgba(255, 255, 255, 0) !important;
}
.seismic img{
    width: 48%;
    height: 500px;
    object-fit: cover;
    background-color: gray;
}
.seismicAsh{
    background-color: var(--ash);
}


@media(max-width: 1300px){
    .suboverlay{
        padding: 0px 5%;
    }
    .seismic{
        padding: 150px 5%;
    }
    .leftSeismic h2{
        font-size: 30px;
    }
    .subSurfaceHero .suboverlay h2{
        font-size: 40px;
    }
    .subSurfaceHero .suboverlay p{
        font-size: var(--big-text);
    }
    .leftSeismic p{
        font-size: var(--small-text);
    }
    .leftSeismic img{
        width: 200px !important;
        height: 60px !important;
        object-position: top;
    }
}
@media(max-width: 1000px){
    .seismic{
        flex-direction: column-reverse;
        row-gap: 50px;
    }
    .seismicAsh{
        row-gap: 50px;
        flex-direction: column;
    }
    .seismic img{
        width: 100%;
        height: 300px;
    }
    .leftSeismic{
        width: 100%;
    }
    .subSurfaceHero .suboverlay h2{
        font-size: 30px;
    }
    .subSurfaceHero .suboverlay p{
        font-size: var(--big-text);
    }
    .leftSeismic h2{
        font-size: 28px;
    }
    .leftSeismic p{
        font-size: var(--small-text);
    }
}

@media(max-width: 750px){
    .subSurfaceHero .suboverlay p{
        width: 100%;
    }
    .leftSeismic h2{
        font-size: 25px;
    }
    .leftSeismic p{
        font-size: var(--small-text);
    }
    .leftSeismic img{
        width: 180px !important;
        top: -5px;
        left: -10px;
        height: 40px !important;
        object-position: top;
    }
    .seismic{
        padding: 100px 5%;
    }
}
@media(max-width: 500px){
    .leftSeismic h2{
        font-size: 23px;
    }
    .seismic img{
        height: 250px;
    }
    .subSurfaceHero .suboverlay h2{
        font-size: 28px;
    }
    .subSurfaceHero .suboverlay p{
        font-size: var(--small-text);
    }
}